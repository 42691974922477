import partition from 'lodash/partition'

import { Case_cases, Case_cases_patient_cases } from '../hasura/graphQlQueries/types/Case'
import { Cases_cases } from '../hasura/graphQlQueries/types/Cases'
import { Consultations_consultations_case } from '../hasura/graphQlQueries/types/Consultations'
import { pluralize } from './helpers'
import { ConsultationsForAiComparison_consultations_case } from '../hasura/graphQlQueries/types/ConsultationsForAiComparison'
import { User_users } from '../hasura/graphQlQueries/types/User'

export enum Modality {
  Xray = 'DX',
  Ultrasound = 'US',
  Catscan = 'CT',
}

export enum ConsultationType {
  Xray = 'DX',
  Ultrasound = 'Ultrasound',
  Catscan = 'CT',
  InternalMedicine = 'Internal Medicine',
  Cardiology = 'Cardiology',
  Neurology = 'Neurology',
}

export interface ConsultationTypeData {
  color: string
  consultationType: ConsultationType
  consultationTypeId: number
  consultationTypeShort: string
  modality?: Modality
  modalityId?: number
}

export const CONSULTATION_TYPES: ConsultationTypeData[] = [
  {
    modality: Modality.Xray,
    color: '#00a6ed',
    modalityId: 1,
    consultationType: ConsultationType.Xray,
    consultationTypeShort: 'DX',
    consultationTypeId: 1,
  },
  {
    modality: Modality.Ultrasound,
    color: '#ffb400',
    modalityId: 2,
    consultationType: ConsultationType.Ultrasound,
    consultationTypeShort: 'US',
    consultationTypeId: 2,
  },
  {
    modality: Modality.Catscan,
    color: '#f6511d',
    modalityId: 3,
    consultationType: ConsultationType.Catscan,
    consultationTypeShort: 'CT',
    consultationTypeId: 34,
  },
  { consultationType: ConsultationType.InternalMedicine, color: '#00B140', consultationTypeId: 68, consultationTypeShort: 'IM' },
  // Purple (#7E3FF2): This bright purple can provide a contrast to the bright blue and red-orange, enhancing the visual appeal of the palette.
  // Teal (#007C77): A deep teal can serve as a cooler tone to balance the overall warmth of the existing colors, and pairs nicely with both the bright blue and vivid yellow.
]

type Case =
  | Case_cases
  | Cases_cases
  | Consultations_consultations_case
  | Case_cases_patient_cases
  | ConsultationsForAiComparison_consultations_case

export const modalityFor = (c?: Case): Modality => {
  if (isUltrasoundCase(c)) {
    return Modality.Ultrasound
  } else if (isCatscanCase(c)) {
    return Modality.Catscan
  } else {
    return Modality.Xray
  }
}

export const getConsultationTypeForModality = (m: Modality) => CONSULTATION_TYPES.find((modality) => modality.modality === m)
export const getConsultationTypeForId = (id: number) => CONSULTATION_TYPES.find((m) => m.consultationTypeId === id)
export const getConsultationTypeData = (consultationType: ConsultationType) =>
  CONSULTATION_TYPES.find((m) => m.consultationType === consultationType)

export const isUltrasoundCase = (c?: Case) =>
  (c?.medical_images || []).some((m) => m.type === getConsultationTypeForModality(Modality.Ultrasound)?.consultationTypeId)

export const isCatscanCase = (c?: Case) =>
  (c?.medical_images || []).some((m) => m.type === getConsultationTypeForModality(Modality.Catscan)?.consultationTypeId)

export const isXrayCase = (c?: Case) => !isUltrasoundCase(c) && !isCatscanCase(c)

export const imageAndVideoCountsForUltrasound = (c?: Case) => {
  if (!c?.medical_images.length) return ''

  const [videoCount, imageCount] = partition(c.medical_images, 'is_video')
  return `${pluralize('image', imageCount.length)} / ${pluralize('cineloop', videoCount.length)}`
}

export const getConsultationTypeOptions = (modality: Modality) =>
  modality === Modality.Xray
    ? [
        { value: ConsultationType.Xray, label: 'DX' },
        { value: ConsultationType.InternalMedicine, label: 'Internal Medicine' },
      ]
    : modality === Modality.Ultrasound
    ? [
        { value: ConsultationType.Ultrasound, label: 'US' },
        { value: ConsultationType.InternalMedicine, label: 'Internal Medicine' },
      ]
    : [{ value: ConsultationType.Catscan, label: 'CT' }]

export const availableConsultationTypeAfterFirstRequest = (c: Case_cases | Cases_cases) => {
  if (c.consultations.length !== 1 || isCatscanCase(c)) return

  const consultationType = getConsultationTypeForId(c.consultations[0].consultation_type_id)?.consultationType
  return consultationType === ConsultationType.InternalMedicine
    ? isXrayCase(c)
      ? ConsultationType.Xray
      : ConsultationType.Ultrasound
    : ConsultationType.InternalMedicine
}

export const canRequestInternalMedicineConsultation = (user: User_users) =>
  user.organization.price_group.some(
    (p) => p.consultation_type_id === getConsultationTypeData(ConsultationType.InternalMedicine)?.consultationTypeId
  )
