import gql from 'graphql-tag'

export const ENTERPRISE_FIELDS = gql`
  fragment enterprise_fields on enterprises {
    id
    ai_enabled
    default_overflow
    default_price_group_id
    default_price_group_id
    enterprise_admin_has_overflow_control
    in_evaluation
    logo_aws_s3_url
    short_name
    vet_has_overflow_control
  }
`
