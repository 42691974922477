import { createSlice } from '@reduxjs/toolkit'

import callFlask from '../callFlask'
import callHasura from '../callHasura'
import { EnterpriseBilling } from '../graphQlQueries/types/EnterpriseBilling'
import { Enterprise_enterprises_by_pk } from '../graphQlQueries/types/Enterprise'
import { Enterprises_enterprises } from '../graphQlQueries/types/Enterprises'
import { defaultNetworkingFailure, defaultNetworkingSuccess, defaultSetLoading } from './common'
import { fetchUserAction } from './users'

import {
  fetchEnterpriseBillingQuery,
  fetchEnterpriseQuery,
  fetchEnterprisesQuery,
  updateEnterpriseDefaultOverflowQuery,
  updateEnterpriseDefaultTurnaroundHoursQuery,
} from '../graphQlQueries/Enterprise'

export interface EnterprisesState {
  enterprise: Enterprise_enterprises_by_pk | null
  enterpriseBilling?: EnterpriseBilling | null
  enterprises: Enterprises_enterprises[]
}

const initialState: EnterprisesState = {
  enterprise: null,
  enterprises: [],
}

const enterprisesSlice = createSlice({
  name: 'enterprises',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    setEnterprise: (state, { payload }: { payload: Enterprise_enterprises_by_pk | null }) => {
      state.enterprise = payload
    },

    setEnterpriseBilling: (state, { payload }: { payload: EnterpriseBilling | null }) => {
      state.enterpriseBilling = payload
    },

    fetchEnterprisesSuccess: (state, { payload }: { payload: Enterprises_enterprises[] }) => {
      state.enterprises = payload
    },
  },
})

export const {
  setLoading,
  networkingFailure,
  networkingSuccess,
  setEnterprise,
  setEnterpriseBilling,
  fetchEnterprisesSuccess,
} = enterprisesSlice.actions

export const enterprisesSelector = (state: any) => state.enterprises

export default enterprisesSlice.reducer

export function updateEnterpriseLogoAction(accessToken: string, userId: string, enterpriseId: number, formData: FormData) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callFlask(`/enterprise/logo?id=${enterpriseId}`, 'PUT', { id: enterpriseId }, formData)
      dispatch(fetchUserAction(accessToken, userId, false))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchEnterprisesAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: Enterprises_enterprises[] = await callHasura(accessToken, fetchEnterprisesQuery())
      dispatch(fetchEnterprisesSuccess(result))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchEnterpriseBillingAction(accessToken: string, id: number, gt: string, lt: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: EnterpriseBilling = await callHasura(accessToken, fetchEnterpriseBillingQuery(id, gt, lt))
      dispatch(setEnterpriseBilling(result))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchEnterpriseAction(id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: Enterprise_enterprises_by_pk = await callHasura('', fetchEnterpriseQuery(id))
      dispatch(setEnterprise(result))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateEnterpriseDefaultOverflowAction(
  accessToken: string,
  userId: string,
  enterpriseId: number,
  default_overflow: boolean
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateEnterpriseDefaultOverflowQuery(enterpriseId, default_overflow))
      dispatch(fetchUserAction(accessToken, userId, false))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateEnterpriseDefaultTurnaroundHoursAction(
  accessToken: string,
  enterpriseId: number,
  default_turnaround_hours: number
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateEnterpriseDefaultTurnaroundHoursQuery(enterpriseId, default_turnaround_hours))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
