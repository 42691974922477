import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import { createSlice } from '@reduxjs/toolkit'

import {
  deleteConsultationQuery,
  fetchConsultationQuery,
  fetchConsultationsForAiComparisonQuery,
  fetchConsultationsForConditionsPageQuery,
  fetchConsultationsForVetQuery,
  fetchConsultationsQuery,
  fetchIncompleteConsultationAddendumsQuery,
  fetchRequestedConsultationsQuery,
  insertConsultationAddendumQuery,
  insertConsultationFollowupQuery,
  PredictionSource,
  specialistPaymentsQuery,
  updateChargeQuery,
  updateConsultationAddendumQuery,
  updateConsultationNotesQuery,
  updateConsultationOverflowQuery,
  updateConsultationSendingVetNotesQuery,
  updateMultiplePaidQuery,
  updatePaidQuery,
  upgradeStatQuery,
} from '../graphQlQueries/Consultation'

import {
  ConsultationsForAiComparison_consultations,
  ConsultationsForAiComparison_consultations_case_patient,
} from '../graphQlQueries/types/ConsultationsForAiComparison'

import CONFIG from '../../config'
import MultiViewerManager from '../../utils/MultiViewerManager'
import callFlask from '../callFlask'
import callHasura from '../callHasura'
import { Cases_cases_patient } from '../graphQlQueries/types/Cases'
import { CompletedConsultationsForVet_consultations } from '../graphQlQueries/types/CompletedConsultationsForVet'
import { CompletedConsultations_consultations } from '../graphQlQueries/types/CompletedConsultations'
import { Conditions_conditions } from '../graphQlQueries/types/Conditions'
import { Consultation_consultations_by_pk } from '../graphQlQueries/types/Consultation'
import { Consultations_consultations, Consultations_consultations_case_patient } from '../graphQlQueries/types/Consultations'
import { IncompleteConsultationAddendums_consultation_addendums } from '../graphQlQueries/types/IncompleteConsultationAddendums'
import { QueryName } from '../queryNames'
import { SpecialistPayments_specialist_payments } from '../graphQlQueries/types/SpecialistPayments'
import { VetStatus } from './users'
import { consultation_addendums_insert_input, consultation_followups_insert_input } from '../../../types/globalTypes'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { emojiFor, enterpriseIdsForUser, keyForAwsS3Url, orderConsultationsForVet, sleep, speciesFor } from '../../lib/helpers'
import { getHeatmapImages, getPossibleConditions } from '../../lib/aiHelpers'
import { setDownloadLinksAction } from './notifications'
import { fetchConditionsQuery } from '../graphQlQueries/Conditions'
import { User_users } from '../graphQlQueries/types/User'
import { DownloadStudyParams } from '../../lib/combineCompareHelpers'
import { Modality } from '../../lib/modalityHelpers'
import { fetchCasesInIdsQuery } from '../graphQlQueries/Case'
import { CasesInIds_cases } from '../graphQlQueries/types/CasesInIds'
import { OrganizationBilling } from '../graphQlQueries/types/OrganizationBilling'
import { fetchOrganizationBillingQuery } from '../graphQlQueries/Billing'

export type Consultation = Consultation_consultations_by_pk | Consultations_consultations

export type CompletedConsultation = CompletedConsultations_consultations | CompletedConsultationsForVet_consultations

export interface ConsultationsState {
  categories: any
  completedConsultations: CompletedConsultation[]
  conditions: Conditions_conditions[]
  consultations: Consultations_consultations[]
  allRequestedConsultations: Consultations_consultations[]
  currentConsultation?: Consultations_consultations
  hasErrors: boolean
  imagesHasErrors: boolean
  imagesLoading: boolean
  isQuerying: any
  loading: boolean
  multiViewerManager?: MultiViewerManager
  presignedCaseImageUrls: string[]
  presignedTaggingImageUrl?: string
  presignedUrl?: string
  presignedUrlPatient?: string
  presignedUrlPatientEmoji?: string
  presignedUrlPatientViewerLink?: string
  specialistPayments: SpecialistPayments_specialist_payments[]
  consultationsForAiComparison?: ConsultationsForAiComparison_consultations[]
  incompleteConsultationAddendums: IncompleteConsultationAddendums_consultation_addendums[]
  organizationBilling?: OrganizationBilling
}

const initialState: ConsultationsState = {
  categories: {},
  completedConsultations: [],
  incompleteConsultationAddendums: [],
  allRequestedConsultations: [],
  conditions: [],
  isQuerying: {},
  consultations: [],
  hasErrors: false,
  imagesHasErrors: false,
  imagesLoading: false,
  loading: false,
  presignedCaseImageUrls: [],
  specialistPayments: [],
}

const consultationsSlice = createSlice({
  name: 'consultations',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchConsultationsSuccess: (
      state,
      { payload }: { payload: { consultations: Consultations_consultations[]; forVet: Consultations_consultations[] } }
    ) => {
      state.consultations = payload.forVet
      state.allRequestedConsultations = payload.consultations
      state.loading = false
      state.hasErrors = false
    },

    setConsultations: (state, { payload }: { payload: Consultations_consultations[] }) => {
      state.consultations = payload
    },

    fetchConsultationSuccess: (state, { payload }: { payload: Consultation }) => {
      state.currentConsultation = payload
      state.loading = false
      state.hasErrors = false
    },

    fetchTaggingImageSuccess: (state, { payload }: { payload: string }) => {
      state.presignedTaggingImageUrl = payload
    },

    fetchImagesSuccess: (state, { payload }) => {
      const unique = uniqBy(
        JSON.parse(JSON.stringify(state.presignedCaseImageUrls)).concat(...payload),
        (url: string) => url.split('?')[0]
      )
      state.presignedCaseImageUrls = unique
    },

    fetchConsultationPdfSuccess: (state, { payload }) => {
      state.presignedUrl = payload.presignedUrl
      state.presignedUrlPatient = payload.patient
      state.presignedUrlPatientEmoji = payload.patientEmoji
      state.presignedUrlPatientViewerLink = payload.viewerLink
      state.loading = false
      state.hasErrors = false
    },

    removePresignedUrl: (state) => {
      state.presignedUrl = undefined
      state.presignedUrlPatient = undefined
    },

    fetchConditionsSuccess: (state, { payload }: { payload: Conditions_conditions[] }) => {
      state.conditions = payload
    },

    fetchCompletedConsultationsSuccess: (state, { payload }: { payload: CompletedConsultation[] }) => {
      state.completedConsultations = payload
      state.loading = false
    },

    fetchOrganizationBillingSuccess: (state, { payload }: { payload: OrganizationBilling }) => {
      state.organizationBilling = payload
      state.loading = false
    },

    setMultiViewerManager: (state, { payload }: { payload: MultiViewerManager }) => {
      // @ts-ignore
      state.multiViewerManager = payload
    },

    unsetCompletedConsultations: (state) => {
      state.completedConsultations = []
    },

    specialistPaymentsSuccess: (state, { payload }: { payload: SpecialistPayments_specialist_payments[] }) => {
      state.specialistPayments = payload
      state.loading = false
      state.hasErrors = false
    },

    fetchConsultationsForAiComparisonSuccess: (state, { payload }: { payload: ConsultationsForAiComparison_consultations[] }) => {
      state.consultationsForAiComparison = payload
    },

    fetchIncompleteConsultationAddendumsSuccess: (
      state,
      { payload }: { payload: IncompleteConsultationAddendums_consultation_addendums[] }
    ) => {
      state.incompleteConsultationAddendums = payload
    },
  },
})

export const {
  fetchCompletedConsultationsSuccess,
  fetchConditionsSuccess,
  fetchConsultationPdfSuccess,
  fetchConsultationsSuccess,
  fetchConsultationSuccess,
  fetchOrganizationBillingSuccess,
  fetchTaggingImageSuccess,
  fetchImagesSuccess,
  networkingFailure,
  networkingSuccess,
  removePresignedUrl,
  setMultiViewerManager,
  setConsultations,
  setLoading,
  unsetCompletedConsultations,
  specialistPaymentsSuccess,
  fetchConsultationsForAiComparisonSuccess,
  fetchIncompleteConsultationAddendumsSuccess,
} = consultationsSlice.actions

export const consultationsSelector = (state: any) => state.consultations

export default consultationsSlice.reducer

export function fetchConsultationAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    try {
      const consultations_by_pk: Consultation = await callHasura(accessToken, fetchConsultationQuery(id))
      dispatch(fetchConsultationSuccess(consultations_by_pk))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function setConsultationsAction(consultations: Consultations_consultations[], vetStatus: VetStatus) {
  return async (dispatch: any) => {
    dispatch(setConsultations(orderConsultationsForVet(consultations, vetStatus)))
  }
}

export function fetchRequestedConsultationsAction(accessToken: string, user: User_users, vetStatus?: VetStatus) {
  return async (dispatch: any) => {
    const enterpriseIds = enterpriseIdsForUser(user)
    const query = fetchRequestedConsultationsQuery(enterpriseIds)
    dispatch(setLoading(query.name))

    try {
      if (!vetStatus) vetStatus = await callFlask(`/radiologist?enterpriseIds=${enterpriseIds}&userId=${user.id}&role=vet`, 'GET')
      if (!vetStatus) throw new Error('No vet status')
      let consultations: Consultations_consultations[] = await callHasura(accessToken, query)
      // only show overflow consultations to Radimal radiologists
      if (!enterpriseIds.includes(CONFIG.RADIMAL_ENTERPRISE_ID)) {
        consultations = consultations.filter((c) => enterpriseIds.includes(c.case.dicom_server?.organization?.enterprise?.id || 0))
      }
      dispatch(fetchConsultationsSuccess({ consultations, forVet: orderConsultationsForVet(consultations, vetStatus) }))
      dispatch(networkingSuccess(query.name))
    } catch (error) {
      dispatch(networkingFailure(query.name))
    }
  }
}

export function completeConsultationAction(data: any, name = QueryName.CompleteConsultation) {
  return async (dispatch: any) => {
    dispatch(setLoading(name))

    try {
      await callFlask(`/consult/complete`, 'POST', data)
      dispatch(networkingSuccess(name))
    } catch (error) {
      dispatch(networkingFailure(name))
    }
  }
}

export function requestConsultationAction(data: any) {
  return async (dispatch: any) => {
    const queryName = QueryName.RequestConsultation
    dispatch(setLoading(queryName))

    try {
      await callFlask(`/consult`, 'POST', data)
      dispatch(networkingSuccess(queryName))
    } catch (error) {
      dispatch(networkingFailure(queryName))
    }
  }
}

export function fetchImagesAction(keys: string[], bucket?: string, mlTagging = false) {
  return async (dispatch: any) => {
    try {
      const images = await callFlask(`/images/presigned`, 'POST', { keys, bucket })
      if (mlTagging && images.length === 1) {
        dispatch(fetchTaggingImageSuccess(images[0]))
      } else {
        dispatch(fetchImagesSuccess(images))
      }
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchImagePermutationsAction(permutations: string[][]) {
  return async (dispatch: any) => {
    const grouped = groupBy(permutations, (c) => c[0])
    await Promise.all(
      Object.keys(grouped).map((label) =>
        dispatch(fetchImagesAction(compact(grouped[label].map((c) => c[1])), `radimal-model-images-cropped-${label}`))
      )
    )
    return
  }
}

export type Patient =
  | Consultations_consultations_case_patient
  | Cases_cases_patient
  | ConsultationsForAiComparison_consultations_case_patient

export function downloadConsultationPdfAction(s3_url: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const presignedUrl = await callFlask(`/consultation/pdf?key=${s3_url.split('s3.amazonaws.com/')[1]}`)
      if (presignedUrl) window.open(presignedUrl, '_blank')
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchConsultationPdfAction(s3_url: string, patient: Patient, viewerLink?: string, openInNewTab = false) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const key = s3_url.split('s3.amazonaws.com/')[1]
      const presignedUrl = await callFlask(`/consultation/pdf?key=${key}`)
      if (openInNewTab) {
        window.open(`${CONFIG.PLATFORM_URL}/consultation?url=${presignedUrl}`, '_blank')
      } else {
        dispatch(
          fetchConsultationPdfSuccess({
            presignedUrl,
            patient: patient.display_name,
            patientEmoji: emojiFor(speciesFor(patient.species)),
            viewerLink,
          })
        )
      }
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function removePresignedUrlAction() {
  return async (dispatch: any) => dispatch(removePresignedUrl())
}

export function fetchConditionsAction(accessToken: string) {
  return async (dispatch: any) => {
    try {
      const result: Conditions_conditions[] = await callHasura(accessToken, fetchConditionsQuery())
      dispatch(fetchConditionsSuccess(result))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchOrganizationBillingAction(accessToken: string, gt: string, lt: string, organization_id: number) {
  return async (dispatch: any) => {
    try {
      const result: OrganizationBilling = await callHasura(accessToken, fetchOrganizationBillingQuery(gt, lt, organization_id))

      dispatch(fetchOrganizationBillingSuccess(result))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchConsultationsForConditionsPageAction(accessToken: string, gt: moment.Moment) {
  return async (dispatch: any) => {
    try {
      const result: ConsultationsForAiComparison_consultations[] = await callHasura(
        accessToken,
        fetchConsultationsForConditionsPageQuery(gt)
      )
      dispatch(fetchConsultationsForAiComparisonSuccess(result))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchConsultationsForAiComparisonAction(
  accessToken: string,
  source: PredictionSource,
  conditions: Conditions_conditions[],
  species: string,
  gt?: string,
  condition_id?: number
) {
  return async (dispatch: any) => {
    const query = fetchConsultationsForAiComparisonQuery(source, species, condition_id, gt)
    dispatch(setLoading(query.name))
    dispatch(fetchConsultationsForAiComparisonSuccess([]))

    try {
      const result: ConsultationsForAiComparison_consultations[] = await callHasura(accessToken, query)
      const filtered =
        condition_id && source === PredictionSource.AI
          ? result.filter((r) => getPossibleConditions(r.case, conditions).some((c) => c.id === condition_id))
          : result
      const keys = compact(flatten(filtered.map((r) => compact(r.case.medical_images.map((m) => m.aws_s3_url)).map(keyForAwsS3Url))))
      await dispatch(fetchImagesAction(keys))
      const heatmapImageKeys = flatten(filtered.map((f) => getHeatmapImages(f.case).map((b: any) => b.heatmap)))
      if (heatmapImageKeys.length) await dispatch(fetchImagesAction(heatmapImageKeys))
      dispatch(networkingSuccess(query.name))
      dispatch(fetchConsultationsForAiComparisonSuccess(filtered))
    } catch (error) {
      dispatch(networkingFailure(query.name))
    }
  }
}

export function fetchCompletedConsultationsAction(
  accessToken: string,
  enterpriseIds: number[],
  gt: string,
  lt: string,
  receivingVetId?: string
) {
  return async (dispatch: any) => {
    const query = QueryName.CompletedConsultations
    dispatch(setLoading(query))

    try {
      let consultations: CompletedConsultation[] = []

      if (receivingVetId) {
        consultations = await callHasura(accessToken, fetchConsultationsForVetQuery(gt, lt, receivingVetId, enterpriseIds))
      } else {
        consultations = await callHasura(accessToken, fetchConsultationsQuery(enterpriseIds, gt, lt))
      }

      dispatch(networkingSuccess(query))
      dispatch(fetchCompletedConsultationsSuccess(consultations))
    } catch (error) {
      dispatch(networkingFailure(query))
    }
  }
}

export function unsetCompletedConsultationsAction() {
  return async (dispatch: any) => dispatch(unsetCompletedConsultations())
}

export function upgradeStatAction(accessToken: string, id: number, price_amount_inc: number, stat_type_id: number) {
  return async (dispatch: any) => {
    const query = upgradeStatQuery(id, price_amount_inc, stat_type_id)
    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)

      dispatch(networkingSuccess(query.name))
    } catch (error) {
      dispatch(networkingFailure(query.name))
    }
  }
}

export function insertConsultationAddendumAction(accessToken: string, object: consultation_addendums_insert_input) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, insertConsultationAddendumQuery(object))

      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertConsultationFollowupAction(accessToken: string, object: consultation_followups_insert_input) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, insertConsultationFollowupQuery(object))

      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateConsultationAddendumAction(
  accessToken: string,
  id: number,
  text: string,
  receiving_vet_id: string,
  private_text?: string
) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateConsultationAddendumQuery(id, text, receiving_vet_id, private_text))

      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateConsultationSendingVetNotesAction(accessToken: string, id: number, sending_vet_notes: string) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateConsultationSendingVetNotesQuery(id, sending_vet_notes))

      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateChargeAction(
  accessToken: string,
  id: number,
  price_amount: number,
  receiving_vet_pay_amount: number | null,
  charge_adjustment_notes: string | null
) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateChargeQuery(id, price_amount, receiving_vet_pay_amount, charge_adjustment_notes))

      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updatePaidAction(accessToken: string, id: number, receiving_vet_paid: boolean) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updatePaidQuery(id, receiving_vet_paid))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateConsultationNotesAction(accessToken: string, id: number, notes: string) {
  return async (dispatch: any) => {
    const query = updateConsultationNotesQuery(id, notes)
    dispatch(setLoading(query.name))
    try {
      await callHasura(accessToken, query)
      await sleep(2)
      dispatch(networkingSuccess(query.name))
    } catch (error) {
      dispatch(networkingFailure(query.name))
    }
  }
}

export function updateMultiplePaidAction(accessToken: string, ids: number[]) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateMultiplePaidQuery(ids))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function downloadConsultationAttachmentsAction(urls: string[]) {
  return async (dispatch: any) => {
    try {
      const results: string[] = await callFlask('/presigned-urls', 'POST', {
        keys: urls.map(keyForAwsS3Url),
        bucket: 'radimal-consultation-attachments',
      })
      results.forEach((url) => window.open(url, '_blank'))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

interface CombineStudies {
  patient_name: string
  ids: number[]
}

export function downloadStudyAction(accessToken: string, params: DownloadStudyParams[]) {
  return async (dispatch: any) => {
    const query = QueryName.DownloadStudies
    dispatch(setLoading(query))

    try {
      let id = ''
      let ids: string[] = []
      let combine: CombineStudies[] = []

      if (params.length === 1 && !params[0].isCombineCompare) {
        id = params[0].studyId
      } else {
        for (const param of params) {
          if (param.isCombineCompare && param.combineCompareCaseIds) {
            if (param.modality === Modality.Catscan || param.modality === Modality.Ultrasound) {
              const cases: CasesInIds_cases[] = await callHasura(accessToken, fetchCasesInIdsQuery(param.combineCompareCaseIds))
              ids.push(...compact(cases.map((c) => c.dicom_server_study_id)))
            } else if (param.patientName) {
              combine.push({ patient_name: param.patientName, ids: param.combineCompareCaseIds })
            }
          } else {
            ids.push(param.studyId)
          }
        }
      }

      const endpoint = `/orthanc/study/download?id=${id || ''}&ids=${ids || ''}`
      const result: { urls: string[] } = await callFlask(endpoint, 'POST', { combine })

      const download = (urls: string[]) => {
        const url = urls.pop()
        const a = document.createElement('a')
        a.setAttribute('href', url!)
        a.setAttribute('download', '')
        a.click()
        if (urls.length) return

        clearInterval(interval)
        dispatch(networkingSuccess(query))
      }

      dispatch(setDownloadLinksAction(result.urls.map((url) => ({ url, text: url.split('.com/')[1].split('?AWSAccessKeyId')[0] }))))
      const interval = setInterval(download, 1000, result.urls)
    } catch (error) {
      dispatch(networkingFailure(query))
    }
  }
}

export function setMultiViewerManagerAction(multiViewerManager: MultiViewerManager) {
  return async (dispatch: any) => dispatch(setMultiViewerManager(multiViewerManager))
}

export function fetchSpecialistPayments(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const specialistPayments: SpecialistPayments_specialist_payments[] = await callHasura(accessToken, specialistPaymentsQuery())

      dispatch(specialistPaymentsSuccess(specialistPayments))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchIncompleteConsultationAddendumsAction(accessToken: string, enterpriseIds: number[]) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: IncompleteConsultationAddendums_consultation_addendums[] = await callHasura(
        accessToken,
        fetchIncompleteConsultationAddendumsQuery(enterpriseIds)
      )

      dispatch(fetchIncompleteConsultationAddendumsSuccess(result))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteConsultationAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteConsultationQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateConsultationOverflowAction(accessToken: string, id: number, overflow: boolean) {
  return async (dispatch: any) => {
    dispatch(setLoading())
    try {
      await callHasura(accessToken, updateConsultationOverflowQuery(id, overflow))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
