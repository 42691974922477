import gql from 'graphql-tag'
import moment from 'moment'

import { consultation_addendums_insert_input } from '../../../types/globalTypes'
import { consultation_followups_insert_input } from '../../../types/globalTypes'

import { QueryName } from '../queryNames'
import { COMPLETED_CONSULTATION_FIELDS } from './fragments/completedConsultationFields'
import { IRX_CONSULTATION_FIELDS } from './fragments/irxConsultationFields'
import { AI_FEED_CONSULTATION_FIELDS } from './fragments/aiFeedConsultationFields'

export const fetchConsultationQuery = (id: number) => ({
  query: gql`
    ${IRX_CONSULTATION_FIELDS}
    query Consultation($id: Int!) {
      consultations_by_pk(id: $id) {
        ...irx_consultation_fields
      }
    }
  `,
  route: 'consultations_by_pk',
  variables: { id },
})

export const fetchRequestedConsultationsQuery = (enterprise_ids: number[], name = QueryName.Consultations) => ({
  query: gql`
    ${IRX_CONSULTATION_FIELDS}
    query Consultations($enterprise_ids: [Int!]!) {
      consultations(
        where: {
          status: { _eq: "requested" }
          _or: [{ case: { dicom_server: { organization: { enterprise_id: { _in: $enterprise_ids } } } } }, { overflow: { _eq: true } }]
        }
        order_by: { id: asc }
      ) {
        ...irx_consultation_fields
      }
    }
  `,
  route: 'consultations',
  variables: { enterprise_ids },
  name,
})

export const fetchConsultationsQuery = (enterpriseIds: number[], gt: string, lt: string) => ({
  query: gql`
    ${COMPLETED_CONSULTATION_FIELDS}
    query CompletedConsultations($enterpriseIds: [Int!]!, $gt: timestamptz!, $lt: timestamptz!) {
      consultations(
        where: {
          completed_at: { _gt: $gt, _lt: $lt }
          status: { _eq: "completed" }
          _or: [
            { case: { dicom_server: { organization: { enterprise_id: { _in: $enterpriseIds } } } } }
            { completed_by_enterprise_id: { _in: $enterpriseIds } }
          ]
        }
        order_by: { completed_at: desc }
      ) {
        ...completed_consultation_fields
      }
    }
  `,
  route: 'consultations',
  variables: { gt, lt, enterpriseIds },
})

export const fetchIncompleteConsultationAddendumsQuery = (enterpriseIds: number[]) => ({
  query: gql`
    query IncompleteConsultationAddendums($enterpriseIds: [Int!]!) {
      consultation_addendums(
        where: { text: { _is_null: true }, consultation: { completed_by_enterprise_id: { _in: $enterpriseIds } } }
      ) {
        created_at
        receiving_vet_id
        receiving_vet {
          display_name
        }
        sending_vet_text
        consultation {
          id
          case {
            patient {
              display_name
            }
          }
        }
      }
    }
  `,
  route: 'consultation_addendums',
  variables: { enterpriseIds },
})

export const fetchConsultationsForVetQuery = (gt: string, lt: string, id: string, enterpriseIds: number[]) => ({
  query: gql`
    ${COMPLETED_CONSULTATION_FIELDS}
    query CompletedConsultationsForVet($gt: timestamptz!, $lt: timestamptz!, $id: String, $enterpriseIds: [Int!]!) {
      consultations(
        where: {
          receiving_vet_id: { _eq: $id }
          _and: { completed_at: { _gt: $gt, _lt: $lt }, _and: { status: { _eq: "completed" } } }
          completed_by_enterprise_id: { _in: $enterpriseIds }
        }
        order_by: { completed_at: desc }
      ) {
        ...completed_consultation_fields
      }
    }
  `,
  route: 'consultations',
  variables: { gt, lt, id, enterpriseIds },
})

export enum PredictionSource {
  AI = 'AI',
  Specialist = 'Specialist',
}

export const fetchConsultationsForConditionsPageQuery = (gt: moment.Moment) => ({
  query: gql`
    ${AI_FEED_CONSULTATION_FIELDS}
    query ConsultationsForAiComparison4($gt: timestamptz!) {
      consultations(
        where: { consultation_type_id: { _eq: 1 }, created_at: { _gt: $gt }, status: { _eq: "completed" } }
        order_by: { completed_at: desc }
        limit: 5000
      ) {
        ...ai_feed_consultation_fields
      }
    }
  `,
  variables: { gt },
  route: 'consultations',
})

export const fetchConsultationsForAiComparisonQuery = (
  source: PredictionSource,
  species: String,
  condition_id?: number,
  gt?: string,
  name = QueryName.FetchConsultationsForAiComparison
) => {
  return condition_id
    ? source === PredictionSource.AI
      ? {
          query: gql`
            ${AI_FEED_CONSULTATION_FIELDS}
            query ConsultationsForAiComparison($species: String!, $gt: timestamptz!) {
              consultations(
                where: {
                  consultation_type_id: { _eq: 1 }
                  created_at: { _gt: $gt }
                  status: { _eq: "completed" }
                  case: { patient: { species: { _eq: $species } } }
                }
                order_by: { completed_at: desc }
                limit: 5000
              ) {
                ...ai_feed_consultation_fields
              }
            }
          `,
          variables: { species, gt },
          route: 'consultations',
          name,
        }
      : {
          query: gql`
            ${AI_FEED_CONSULTATION_FIELDS}
            query ConsultationsForAiComparison2($species: String!, $condition_id: Int!, $gt: timestamptz!) {
              consultations(
                where: {
                  consultation_type_id: { _eq: 1 }
                  created_at: { _gt: $gt }
                  status: { _eq: "completed" }
                  case: { patient: { species: { _eq: $species } } }
                  predictions: { condition_id: { _eq: $condition_id } }
                }
                order_by: { completed_at: desc }
                limit: 500
              ) {
                ...ai_feed_consultation_fields
              }
            }
          `,
          route: 'consultations',
          variables: { condition_id, species, gt },
          name,
        }
    : {
        query: gql`
          ${AI_FEED_CONSULTATION_FIELDS}
          query ConsultationsForAiComparison3($species: String!) {
            consultations(
              where: {
                consultation_type_id: { _eq: 1 }
                status: { _eq: "completed" }
                case: { patient: { species: { _eq: $species } }, medical_images: { type: { _neq: 2 } } }
              }
              order_by: { completed_at: desc }
              limit: 50
            ) {
              ...ai_feed_consultation_fields
            }
          }
        `,
        route: 'consultations',
        variables: { species },
        name,
      }
}

export const upgradeStatQuery = (id: number, price_amount_inc: number, stat_type_id: number, name = QueryName.UpgradeStat) => ({
  query: gql`
    mutation UpgradeStat($id: Int!, $price_amount_inc: float8!, $stat_type_id: Int!) {
      update_consultations_by_pk(
        pk_columns: { id: $id }
        _inc: { price_amount: $price_amount_inc }
        _set: { stat_type_id: $stat_type_id, upgraded_at: "now()" }
      ) {
        id
        price_amount
        stat_type_id
      }
    }
  `,
  route: 'update_users_by_pk',
  variables: { id, price_amount_inc, stat_type_id },
  name,
})

export const updateChargeQuery = (
  id: number,
  price_amount: number,
  receiving_vet_pay_amount: number | null,
  charge_adjustment_notes: string | null
) => ({
  query: gql`
    mutation UpdateCharge($id: Int!, $price_amount: float8!, $receiving_vet_pay_amount: float8, $charge_adjustment_notes: String) {
      update_consultations_by_pk(
        pk_columns: { id: $id }
        _set: {
          charge_adjustment_notes: $charge_adjustment_notes
          price_amount: $price_amount
          receiving_vet_pay_amount: $receiving_vet_pay_amount
        }
      ) {
        id
      }
    }
  `,
  route: 'update_consultations_by_pk',
  variables: { id, charge_adjustment_notes, price_amount, receiving_vet_pay_amount },
})

export const insertConsultationAddendumQuery = (object: consultation_addendums_insert_input) => ({
  query: gql`
    mutation InsertConsultationAddendum($object: consultation_addendums_insert_input!) {
      insert_consultation_addendums_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_consultation_addendums_one',
  variables: { object },
})

export const insertConsultationFollowupQuery = (object: consultation_followups_insert_input) => ({
  query: gql`
    mutation InsertConsultationFollowup($object: consultation_followups_insert_input!) {
      insert_consultation_followups_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_consultation_followups_one',
  variables: { object },
})

export const updateConsultationAddendumQuery = (id: number, text: string, receiving_vet_id: string, private_text?: string) => ({
  query: gql`
    mutation UpdateConsultationAddendum($id: Int!, $text: String!, $private_text: String, $receiving_vet_id: String!) {
      update_consultation_addendums_by_pk(
        pk_columns: { id: $id }
        _set: { text: $text, private_text: $private_text, receiving_vet_id: $receiving_vet_id }
      ) {
        id
      }
    }
  `,
  route: 'update_consultation_addendums_by_pk',
  variables: { id, text, private_text, receiving_vet_id },
})

export const updateConsultationFollowupQuery = (id: number, text: string, receiving_vet_id: string, private_text?: string) => ({
  query: gql`
    mutation UpdateConsultationFollowup($id: Int!, $text: String!, $private_text: String, $receiving_vet_id: String!) {
      update_consultation_addendums_by_pk(
        pk_columns: { id: $id }
        _set: { text: $text, private_text: $private_text, receiving_vet_id: $receiving_vet_id }
      ) {
        id
      }
    }
  `,
  route: 'update_consultation_followups_by_pk',
  variables: { id, text, private_text, receiving_vet_id },
})

export const updateConsultationSendingVetNotesQuery = (id: number, sending_vet_notes: string) => ({
  query: gql`
    mutation UpdateConsultationSendingVetNotes($id: Int!, $sending_vet_notes: String!) {
      update_consultations_by_pk(pk_columns: { id: $id }, _set: { sending_vet_notes: $sending_vet_notes }) {
        id
      }
    }
  `,
  route: 'update_consultations_by_pk',
  variables: { id, sending_vet_notes },
})

export const updatePaidQuery = (id: number, receiving_vet_paid: boolean) => ({
  query: gql`
    mutation UpdatePaid($id: Int!, $receiving_vet_paid: Boolean!) {
      update_consultations_by_pk(pk_columns: { id: $id }, _set: { receiving_vet_paid: $receiving_vet_paid }) {
        id
      }
    }
  `,
  route: 'update_consultations_by_pk',
  variables: { id, receiving_vet_paid },
})

export const updateMultiplePaidQuery = (ids: number[]) => ({
  query: gql`
    mutation UpdateMultiplePaid($ids: [Int!]!) {
      update_consultations(where: { id: { _in: $ids } }, _set: { receiving_vet_paid: true }) {
        returning {
          id
        }
      }
    }
  `,
  route: 'update_consultations',
  variables: { ids },
})

export const updateConsultationNotesQuery = (id: number, receiving_vet_notes: string, name = QueryName.UpdateConsultationNotes) => ({
  query: gql`
    mutation UpdateConsultationNotes($id: Int!, $receiving_vet_notes: String!) {
      update_consultations_by_pk(pk_columns: { id: $id }, _set: { receiving_vet_notes: $receiving_vet_notes }) {
        id
      }
    }
  `,
  route: 'update_consultations_by_pk',
  variables: { id, receiving_vet_notes },
  name,
})

export const updateConsultationOverflowQuery = (id: number, overflow: boolean) => ({
  query: gql`
    mutation UpdateConsultationOverflow($id: Int!, $overflow: Boolean!, $overflowed_at: timestamptz) {
      update_consultations_by_pk(pk_columns: { id: $id }, _set: { overflow: $overflow, overflowed_at: $overflowed_at }) {
        id
      }
    }
  `,
  route: 'update_consultations_by_pk',
  variables: { id, overflow, overflowed_at: overflow ? 'now()' : null },
})

export const specialistPaymentsQuery = () => ({
  query: gql`
    query SpecialistPayments {
      specialist_payments {
        id
        amount
        consultation_type_id
        is_exotic
        level
        stat_type_id
      }
    }
  `,
  route: 'specialist_payments',
})

export const deleteConsultationQuery = (id: number) => ({
  query: gql`
    mutation DeleteConsultation($id: Int!) {
      delete_consultations_by_pk(id: $id) {
        id
      }
    }
  `,
  route: 'delete_consultations_by_pk',
  variables: { id },
})

export const requestedExoticsCountQuery = () => ({
  query: gql`
    query RequestedExoticsCount {
      consultations_aggregate(where: { case: { patient: { species: { _nin: ["cat", "dog"] } } }, status: { _eq: "requested" } }) {
        aggregate {
          count
        }
      }
    }
  `,
})
