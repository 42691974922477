import moment from 'moment'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import isNumber from 'lodash/isNumber'
import groupBy from 'lodash/groupBy'

import CONFIG from '../config'
import { Case_cases, Case_cases_dicom_server_organization_price_group } from '../hasura/graphQlQueries/types/Case'
import { CatscanRegions } from '../components/request-consult/config'
import { CompletedConsultation } from '../hasura/slices/consultations'
import { ConsultationType, Modality, getConsultationTypeData, modalityFor } from '../lib/modalityHelpers'
import { Consultations_consultations } from '../hasura/graphQlQueries/types/Consultations'
import { EnterpriseBilling_overflow_consults } from '../hasura/graphQlQueries/types/EnterpriseBilling'
import { SpecialistPayments_specialist_payments } from '../hasura/graphQlQueries/types/SpecialistPayments'
import { Species, formatDollarAmount, isExotic, requestedImagesCount } from '../lib/helpers'
import { StripeInvoiceItem } from '../interfaces/StripeInvoiceItem'
import { User_users } from '../hasura/graphQlQueries/types/User'
import { VetStatus } from '../hasura/slices/users'
import { additionalImagesCount } from '../lib/combineCompareHelpers'
import { getExtraImagePrices, getStatPrice, statDescription, statTypeDescription } from '../lib/pricingHelpers'
import { isWeekendEst } from '../lib/timeHelpers'

// @ts-ignore
import sharedConfig from '../../sharedConfig.json'

export interface StatOption {
  disabled: boolean
  label: string
  time?: string
  value?: number
  hours: number
}

const US_VIDEO_THRESHOLD = 15
const US_IMAGE_THRESHOLD = 60
const US_EXTRA_IMAGE_VIDEO_SPECIALIST_PAY = 25

export default class PriceCalculator {
  case: Case_cases

  DEFAULT_PRICE = 65

  constructor(c: Case_cases) {
    this.case = c
  }

  costs(
    otherCases: string | null,
    removedImagesCount: number,
    removedVideosCount: number,
    species: Species,
    consultationType: ConsultationType,
    statTypeId?: number,
    region?: CatscanRegions
  ) {
    const patientIsExotic = isExotic(species)
    const modality = getConsultationTypeData(consultationType)?.modality || Modality.Xray
    const prices = (this.case.dicom_server?.organization?.price_group || []).filter((p) =>
      patientIsExotic ? p.is_exotic : !p.is_exotic
    )

    // get base price
    const basePrice: number =
      prices.find(
        (p) =>
          !p.software_charge_type_id &&
          !p.stat_type &&
          !p.addon_type &&
          !p.addon &&
          p.consultation_type_id === getConsultationTypeData(consultationType)?.consultationTypeId
      )?.amount || this.DEFAULT_PRICE

    // get stat price
    let statPrice = this.case && statTypeId ? getStatPrice(statTypeId, this.case, modality) : 0
    if (statTypeId) statPrice += this.getWeekendStatPrice(prices)

    // get extra image/region price
    const extraImageCost = (() => {
      const extraImageXrayPrices = getExtraImagePrices(prices, patientIsExotic, modality).reverse()

      if (consultationType === ConsultationType.Catscan && region) {
        const price = this.priceForCatscanExtraRegions(region, prices)
        if (price) return [price.amount, 0]
      } else if (consultationType === ConsultationType.Xray) {
        const imagesCount = this.case.medical_images.length + additionalImagesCount(otherCases) - removedImagesCount

        for (const price of extraImageXrayPrices) {
          const thresholds = price.addon?.additional_data
          if (imagesCount > thresholds['threshold']) {
            return [price.amount, `${thresholds['threshold']} images`]
          }
        }
      } else if (consultationType === ConsultationType.Ultrasound) {
        const [videosCount, imagesCount] = partition(this.case.medical_images, 'is_video').map((a) => a.length)
        for (const price of extraImageXrayPrices) {
          const thresholds = price.addon?.additional_data
          if (
            imagesCount - removedImagesCount > thresholds['image_threshold'] ||
            videosCount - removedVideosCount > thresholds['video_threshold']
          ) {
            return [price.amount, `${thresholds['image_threshold']} images / ${thresholds['video_threshold']} cineloops`]
          }
        }
      }
    })()

    const totalPriceWithoutStat = basePrice + (extraImageCost ? extraImageCost[0] : 0)
    // extra image cost applied at time of consult completion since images may come in after request
    const totalPriceWithoutExtraImageCost = basePrice + statPrice

    return {
      basePrice,
      extraImageCost,
      statPrice,
      totalPriceWithoutExtraImageCost,
      totalPriceWithoutStat,
    }
  }

  getWeekendStatPrice(prices: Case_cases_dicom_server_organization_price_group[]) {
    const weekendStatPrice = prices.find((p) => p.addon?.display_name === 'Weekend STAT')
    if (!weekendStatPrice || !isWeekendEst()) return 0

    return weekendStatPrice.amount
  }

  getStatOptions(
    defaultTurnaroundTime: number,
    consultationType: ConsultationType,
    disableLongStat: boolean,
    vetStatus?: VetStatus,
    isUpgrade?: boolean,
    totalPriceBeforeStat?: number,
    isExotic?: boolean
  ): StatOption[] {
    const priceGroup = this.case.dicom_server?.organization?.price_group
    if (!priceGroup) return [{ label: `${defaultTurnaroundTime}hr`, disabled: false, hours: defaultTurnaroundTime }]

    return orderBy(
      priceGroup
        .filter((p) => {
          const matchesModality = p.consultation_type_id === getConsultationTypeData(consultationType)?.consultationTypeId
          const currentHour = moment(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })).hour()
          const isOffHours = currentHour < 8 || currentHour >= 18
          const statHours = p.stat_type?.hours || 0
          const disable = (disableLongStat && statHours > 1) || (isOffHours && statHours > 2)
          const matchesSpecies = isExotic ? p.is_exotic : !p.is_exotic
          if (consultationType === ConsultationType.Catscan) {
            const isBaseOrStatAddOn = !p.addon || p.addon?.display_name.includes('STAT')
            return (!isUpgrade || p.stat_type) && matchesSpecies && !disable && matchesModality && isBaseOrStatAddOn
          } else {
            return (!isUpgrade || p.stat_type) && matchesSpecies && !p.addon_type && !p.addon && !disable && matchesModality
          }
        })
        .map((p) => {
          const disabled = p.stat_type !== null && !vetStatus?.stat_available
          const isStat = isNumber(p.stat_type?.hours)
          const label =
            statTypeDescription(defaultTurnaroundTime, p.stat_type, isExotic) +
            (totalPriceBeforeStat
              ? ` ($${formatDollarAmount(totalPriceBeforeStat + (isStat ? p.amount + this.getWeekendStatPrice(priceGroup) : 0))})`
              : ` (+$${formatDollarAmount(p.amount)})`)
          let time
          const hours = isStat ? p.stat_type?.hours || 0 : isExotic ? 72 : defaultTurnaroundTime
          if (isStat) {
            time = p.stat_type!.hours === 0 ? 'Immediate' : `${p.stat_type!.hours}hr`
          }
          return { label, time, value: p.stat_type?.id, disabled, hours }
        }),
      ['hours'],
      ['desc']
    )
  }

  static invoiceItemsFromConsultations(
    completedConsultations: (CompletedConsultation | EnterpriseBilling_overflow_consults)[],
    isOverflow = false
  ) {
    return Object.entries(
      groupBy(completedConsultations, (c) => {
        let description = `${isOverflow ? 'Overflow ' : ''}${c.consultation_type.display_name} full consult`

        if (c.stat_type) {
          description += ` ${statDescription(c.stat_type)}`
        }

        return [description, isOverflow ? c.overflow_price_amount : c.price_amount]
      })
    ).map(
      (arr): StripeInvoiceItem => {
        const [description, price] = arr[0].split(',')
        return { description, unit_amount: parseFloat(price) * 100 || 0, quantity: arr[1].length }
      }
    )
  }

  priceForCatscanExtraRegions(region: CatscanRegions, priceGroups: Case_cases_dicom_server_organization_price_group[]) {
    const extraRegionsPriceGroups = priceGroups.filter((p) => p.addon?.display_name === 'Extra Regions')
    switch (region) {
      case CatscanRegions.WholeBody:
        return extraRegionsPriceGroups.find((p) => p.addon?.additional_data.threshold === 2)
      case CatscanRegions.TwoRegions:
        return extraRegionsPriceGroups.find((p) => p.addon?.additional_data.threshold === 1)
      case CatscanRegions.OneRegion:
        return
    }
  }

  static receivingVetPayAmount = (
    specialistPayments: SpecialistPayments_specialist_payments[],
    consultation?: Consultations_consultations,
    vetStatus?: VetStatus,
    user?: User_users,
    checked?: string[]
  ) => {
    // get the amount for the stat type, species, and modality
    const modality = modalityFor(consultation?.case)
    const patientIsExotic = isExotic(consultation?.case.patient.species)
    let amount: number | undefined = specialistPayments.find((s) => {
      const matchesStatType = s.stat_type_id === (consultation?.stat_type?.id || null)
      const matchesExotic = patientIsExotic ? s.is_exotic : !s.is_exotic
      const matchesModality = s.consultation_type_id === consultation?.consultation_type_id
      return matchesStatType && matchesExotic && matchesModality
    })?.amount

    // add bonuses
    const applyStatPlusBonus = Boolean(vetStatus?.stat_plus_vet_ids.includes(user?.id || ''))
    if (!amount || !consultation) return { amount, amountWithBonuses: 0, applyStatPlusBonus }

    const addStatBonus = Boolean(vetStatus?.stat_bonus_activated && consultation?.stat_type)
    if (addStatBonus) amount += CONFIG.STAT_OFF_HOUR_BONUS
    if (vetStatus?.holiday_bonus_activated) amount += CONFIG.HOLIDAY_BONUS

    // add extra image cost
    if (modality === Modality.Xray) {
      const imageCount = requestedImagesCount(consultation)
      const surplusImagesKeyPrefix = `SURPLUS_IMAGES${patientIsExotic ? '_EXOTIC' : ''}`
      if (imageCount > sharedConfig[`${surplusImagesKeyPrefix}_TWO_THRESHOLD`]) {
        amount += sharedConfig[`${surplusImagesKeyPrefix}_TWO_VET_PAY`]
      } else if (imageCount > sharedConfig[`${surplusImagesKeyPrefix}_ONE_THRESHOLD`]) {
        amount += sharedConfig[`${surplusImagesKeyPrefix}_ONE_VET_PAY`]
      }
    } else if (modality === Modality.Ultrasound) {
      // TODO: - use addon table for thresholds and model in specialist payments table
      const [videosCount, imagesCount] = partition(consultation.case.medical_images, 'is_video').map((a) => a.length)
      if (videosCount > US_VIDEO_THRESHOLD || imagesCount > US_IMAGE_THRESHOLD) amount += US_EXTRA_IMAGE_VIDEO_SPECIALIST_PAY
    } else if (modality === Modality.Catscan && checked?.length) {
      // TODO: - model in specialist payments table
      if (checked.length === 3) amount += 136
      if (checked.length === 2) amount += 35
    }

    const amountWithBonuses = applyStatPlusBonus
      ? amount + (consultation?.stat_type ? CONFIG.STAT_LIGHT_ON_BONUS : CONFIG.STAT_LIGHT_ON_BONUS_NON_STAT)
      : amount

    return { amount, amountWithBonuses, applyStatPlusBonus }
  }
}
